<template>
  <div class="card-warning">
    <slot name="icon">⚠️</slot>
    <slot name="text"></slot>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setun() {},
})
</script>

<style scoped>
.card-warning {
  @apply p-2 pl-3 pr-3 mb-4;
  background-color: theme('colors.blue.tertiary');
  color: #e57049;
  font-size: 14px;
  border-radius: 4px;
}
</style>

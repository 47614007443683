<template>
  <AppModal v-model="model" :title="$t('ComponentBankDetailsModal.TitleModal').value" scroll-header>
    <template #header="{ dismiss }">
      <AppModalHeader>
        <template #left>
          <XeBackButton
            icon="<"
            :name="$t('AppPaymentCardModal.GoBackTextButton').value"
            analytics-name="bank-details-modal-back"
            @click="dismiss"
          />
        </template>
        <h1>{{ $t('BankDetailsModal.EditText').value }} {{ title }}</h1>
        <template #right>
          <AppButton
            theme="icon"
            class="icon icon-trash-can"
            analytics-name="bank-details-modal-delete"
            @click="onDelete"
          >
            <AppIcon :name="$t('RecipientDetailsModal.CloseTooltipText').value">
              <IconTrashCan />
            </AppIcon>
          </AppButton>
        </template>
      </AppModalHeader>
    </template>

    <CardWarning v-if="payment.disabled">
      <template #text>
        {{ $t('BankDetailsModal.CardDisabledWarningText').value }}
        <!-- This account has been disabled -->
      </template>
    </CardWarning>
    <CardWarning v-else-if="isPendingVerificationWarningShow">
      <template #text>
        <strong> {{ $t('BankDetailsModal.PendingVerificationWarningTitle').value }}</strong>
        <p>{{ $t('BankDetailsModal.PendingVerificationWarningText').value }}</p>
        <!-- Account pending verification -->
      </template>
    </CardWarning>

    <AppInputText
      :disabled="payment.disabled"
      v-model="payment.nickname"
      analytics-name="add-payment-card-modal-expiry"
      :label="$t('BankDetailsModal.NicknameLabel').value"
      :placeholder="payment.nickname"
      autocomplete="off"
      inputmode="text"
    />

    <AppInputText
      disabled
      v-model="payment.bankName"
      analytics-name="add-payment-card-modal-expiry"
      :label="$t('BankDetailsModal.BankNameLabel').value"
      :placeholder="payment.bankName"
      autocomplete="off"
      inputmode="text"
    />

    <AppInputText
      disabled
      v-model="payment.maskedAccountNumber"
      analytics-name="add-payment-card-modal-expiry"
      :label="$t('BankDetailsModal.AccountNumberLabel').value"
      :placeholder="payment.maskedAccountNumber"
      autocomplete="off"
      inputmode="text"
    />

    <AppInputText
      disabled
      v-model="payment.currency"
      analytics-name="add-payment-card-modal-expiry"
      :label="$t('BankDetailsModal.BankAccountCurrencyLabel').value"
      :placeholder="payment.currency"
      autocomplete="off"
      inputmode="text"
    />

    <template #footer>
      <AppCardFooter :disabled="isSaving">
        <AppButton
          :loading="isSaving || isSavingState"
          type="submit"
          :disabled="payment.disabled || isSavingState"
          analytics-name="add-payment-card-modal-add"
          @click="submit"
        >
          {{ $t('BankDetailsModal.SaveButtonText').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppModal>
</template>

<script>
import { reactive, ref, computed, nextTick } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'
import CardWarning from '@galileo/components/CardWarning/CardWarning'
import { PaymentMethodEnums } from '@galileo/models/Transaction/app'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'

import { BANK_ACCOUNT_STATUS_ENUM } from '@galileo/constants/directDebitVerificationStatuses.const.ts'

import { IconEdit, IconTrashCan } from '@oen.web.vue2/icons'

import {
  useVModel,
  AppButton,
  AppIcon,
  AppFigure,
  AppModal,
  AppModalHeader,
  AppCardFigureTitle,
  AppSkeleton,
  AppBackButton,
  AppDescriptionList,
  AppDescriptionListItem,
  AppInputText,
  AppCardFooter,
  useAlert,
} from '@oen.web.vue2/ui'

import {
  useI18nStore,
  useAppStore,
  usePaymentsStore,
  useSendMoneyStore,
  useAuthStore,
  useQuickTransferStore,
} from '@galileo/stores'

export default {
  name: 'BankDetailsModal',
  components: {
    AppButton,
    IconEdit,
    AppFigure,
    AppIcon,
    AppModal,
    AppModalHeader,
    AppCardFigureTitle,
    IconTrashCan,
    AppSkeleton,
    AppBackButton,
    AppDescriptionList,
    AppDescriptionListItem,
    XeBackButton,
    AppInputText,
    AppCardFooter,
    CardWarning,
    useAlert,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    shouldShowVerificationWarning: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)

    const router = useRouter()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const paymentsStore = usePaymentsStore()
    const quickTransferStore = useQuickTransferStore()

    const { add: showSnackAlert } = useAlert()

    const payment = reactive(paymentsStore.selectedPaymentMethod)
    const isSaving = ref(false)
    const sendMoneyStore = useSendMoneyStore()

    const country = authStore.userProfile.country
    const paymentMethodsList = computed(() => sendMoneyStore.form.paymentMethods)

    const isPendingVerificationWarningShow = computed(
      () =>
        payment.verificationStatus === BANK_ACCOUNT_STATUS_ENUM.ACCOUNT_UNVERIFIED &&
        (quickTransferStore.isCurrentTransactionQuickTransfer ||
          props.shouldShowVerificationWarning)
    )

    //getting the correct trasnlated value for the type of the payment account
    let paymentMethod = PaymentMethodEnums['DirectDebit']
    let title = $t(paymentMethod.textKey).value
    if (country === 'US') {
      title = $t('ComponentXeTransferDetails.PaymentMethodBankAccountACH').value
    } else if (country === 'CA') {
      title = $t('ComponentXeTransferDetails.PaymentMethodBankAccountEFT').value
    }

    const onDelete = () => {
      router.push({ path: 'delete' })
    }

    const isSavingState = ref(false)

    const submit = async () => {
      if (!isSavingState.value && model.value) {
        isSavingState.value = true
        isSaving.value = true

        try {
          await paymentsStore.editBankAccountNickname(payment)
          model.value = false
          isSaving.value = false
          if (quickTransferStore.isCurrentTransactionQuickTransfer) {
            showSnackAlert($t('PageQuickTransfer.AccountInfoUpdatedAlert').value)
          }
        } catch (ex) {
          appStore.messageBoxGenericError()
          isSaving.value = false
        }

        nextTick(() => {
          isSavingState.value = false
        })
      }
    }

    return {
      model,
      onDelete,
      payment,
      submit,
      title,
      $t,
      isSaving,
      isSavingState,
      isPendingVerificationWarningShow,
    }
  },
}
</script>

<style scoped>
.icon-trash-can.button.button--icon {
  @screen sm {
    @apply w-6 h-6 !important;

    &:hover:not(:disabled) {
      @apply bg-transparent;
    }

    ::v-deep .button-inner {
      @apply w-6 h-6;
      @apply text-icon-primary;

      &:hover {
        @apply text-gray-darker;
      }
    }
  }
}

::v-deep .card-footer > .button {
  @apply w-full !important;
}
</style>
